import React from "react"
import styled from "@emotion/styled"
import moment from "moment"

import { setPlanCardData } from "../utils/helpers"

import {
  PlanDisplayCard,
  TravelPlanDisplayCard,
  TravellersDisplayCard,
} from "."
import useInsurersLogos from "../hooks/use-insurers-logos"

const getTravelPlanFromSearchResult = searchPolicyResult => ({
  travelFrom: searchPolicyResult.policy.application.travel_plan.from,
  travelTo: searchPolicyResult.policy.application.travel_plan.to,
  startDate: moment(
    searchPolicyResult.policy.application.travel_plan.departing_date
  ),
  endDate: moment(
    searchPolicyResult.policy.application.travel_plan.returning_date
  ),
  purpose: searchPolicyResult.policy.application.travel_plan.purpose
})

const getInsurerFromSearchResult = searchPolicyResult => {
  return searchPolicyResult &&
    searchPolicyResult.policy_number &&
    searchPolicyResult.policy_number.toLowerCase().startsWith("dtc")
    ? "destination"
    : searchPolicyResult.policy_number.toLowerCase().startsWith("vtc")
    ? "gms"
    : "tugo"
}

const getTravelersFromSearchResult = searchPolicyResult => {
  if (searchPolicyResult) return searchPolicyResult.policy.application.travelers
}

const PolicyLong = ({ searchPolicyResult }) => {
  const logos = useInsurersLogos()
  const insurer = getInsurerFromSearchResult(searchPolicyResult)
  return (
    <Wrapper>
      <PlanDisplayCard
        plan={{
          ...setPlanCardData(
            insurer,
            logos[insurer].fluid,
            searchPolicyResult.policy.quote
              ? searchPolicyResult.policy.quote.price &&
                  searchPolicyResult.policy.quote.price.premium
              : "premium",
            searchPolicyResult.policy.application.options.map(i => i.code),
            moment(searchPolicyResult.return_date).diff(
              moment(searchPolicyResult.departure_date),
              "days"
            ) + 1
          ),
          coverage: searchPolicyResult.policy.application.policy.coverage,
          deductible: searchPolicyResult.policy.application.policy.deductible,
        }}
        noChangeBtn
        tripStartDate={moment(searchPolicyResult.departure_date)}
        tripEndDate={moment(searchPolicyResult.return_date)}
      />
      <TravelPlanDisplayCard
        noEditBtn
        plan={getTravelPlanFromSearchResult(searchPolicyResult)}
      />
      <TravellersDisplayCard
        noEditBtn
        travelers={getTravelersFromSearchResult(searchPolicyResult)}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  row-gap: 1.5rem;
  margin-top: 3rem;
`

export default PolicyLong

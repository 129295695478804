import ACTIONS from "../actions/actionTypes"

const initConditions = {}

export const apiCallStatus = (state = null, action) => {
  const { type, err, endpoint } = action

  switch (type) {
    case ACTIONS.API_CALL_ERROR:
      return {
        message: `error occured when calling ${endpoint}, error message: ${err.message}`,
        statusCode: -1,
        endpoint,
        errorReason:
          err.response && err.response.data && err.response.data.error,
      }
    case ACTIONS.BEGIN_API_CALL:
      return { message: `call to ${endpoint} started`, statusCode: 0, endpoint }
    case ACTIONS.API_CALL_END:
      return { message: `call to ${endpoint} ended`, statusCode: 1, endpoint }
    default:
      return state
  }
}

export const apiHealth = (state = null, action) => {
  if (action.type === ACTIONS.GET_API_HEALTH) {
    return action.payload
  }
  return state
}

export const tokenExpiryTime = (
  state = typeof window !== "undefined" &&
    window.localStorage.getItem("tokenExpiryTime"),
  action
) => {
  if (action.type === ACTIONS.SET_TOKEN_EXPIRY_TIME)
    return Number.parseInt(state) + action.payload
  return state
}

// export const countries = (state = [], action) => {
//   if (action.type === ACTIONS.GET_COUNTRIES) {
//     return action.payload
//   }
//   return state
// }

export const conditions = (state = initConditions, action) => {
  if (action.type === ACTIONS.GET_CONDITIONS) {
    return action.payload
  }
  return state
}

const initQuotes = { gms: {}, tugo: {}, tugoStudent: {}, destination: {} }

export const quotes = (state = initQuotes, action) => {
  const { type, payload } = action
  switch (type) {
    case ACTIONS.GET_QUOTE_GMS:
      return { ...state, gms: payload }
    case ACTIONS.GET_QUOTE_TUGO:
      return { ...state, tugo: payload }
    case ACTIONS.GET_QUOTE_TUGO_STUDENT:
      return { ...state, tugoStudent: payload }
    case ACTIONS.GET_QUOTE_DESTIN:
      return { ...state, destination: payload }
    case ACTIONS.CLEAR_QUOTES:
      return initQuotes
    default:
      return state
  }
}

export const allQuotesLoaded = (state = false, action) => {
  const { type, payload } = action
  if (type === ACTIONS.SET_QUOTES_LOADED) return payload
  return state
}

const initPurchaseResult = { gms: {}, tugo: {}, tugoStudent: {}, destination: {} }

export const purchaseResult = (state = initPurchaseResult, action) => {
  const { type, payload, insurer } = action
  if (type === ACTIONS.MAKE_PURCHASE) return { ...state, [insurer]: payload }
  if (type === ACTIONS.CLEAR_PURCHASE_RESULT) return initPurchaseResult
  return state
}

export const searchPolicyResult = (state = null, action) => {
  const { type, payload } = action
  if (type === ACTIONS.GET_POLICY)
    return payload.length && payload.length > 0 ? payload[0] : "not found"
  if (type === ACTIONS.CLEAR_POLICY) return null
  return state
}

export const contactEmail = (state = null, action) => {
  const { type, payload } = action
  if (type === ACTIONS.SEND_CONTACT) return payload
  return state
}

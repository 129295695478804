import React from "react"
import { css } from "@emotion/core"
import { navigate } from "gatsby"
import { useIntl } from "react-intl"

import heroImage from "../../images/come-back-later.svg"
import { PrimaryButton, SecondaryButton } from "./buttons"
import { mq } from "../utils/media-query"

const Hero = () => {
  const intl = useIntl()
  const onProceedToNextPage = () => {
    navigate("/get-quote-details")
  }

  return (
    <header
      css={css`
        padding: 5rem 0 6rem 0;
        background-color: var(--clr-neutral-200);
        position: relative;
      `}
    >
      <div
        css={css`
          height: 100%;
          display: grid;
          justify-items: center;
          align-items: center;
          width: 90vw;
          margin: 0 auto;
          max-width: 1170px;

          ${mq[1]} {
            grid-template-columns: repeat(12, 1fr);
          }
        `}
      >
        <article
          css={css`
            margin-bottom: 2rem;
            /* 992px */
            ${mq[1]} {
              grid-row: 1/1;
              grid-column: 1 / span 7;
            }
          `}
        >
          <div
            css={css`
              margin-right: 3rem;
            `}
          >
            <h1>
              {intl.formatMessage({ id: "home.hero1" })} <br />
              {intl.formatMessage({ id: "home.hero2" })}
            </h1>
            <h4
              css={css`
                font-family: var(--ff-quaternary);
                font-weight: 400;
                font-size: 1.2rem;
                text-transform: none;
                padding: 2rem 0;
                line-height: 1.5;

                ${mq[1]} {
                  font-size: 1rem;
                }
              `}
            >
              {intl.formatMessage({ id: "home.subcap" })}
            </h4>
            <div
              css={css`
                display: flex;

                @media (max-width: 520px) {
                  display: grid;
                  row-gap: 1.5rem;
                }
              `}
            >
              <PrimaryButton
                css={css`
                  @media (max-width: 520px) {
                    max-width: 190px;
                  }
                `}
                margin="0 2rem 0 0"
                onClick={onProceedToNextPage}
              >
                {intl.formatMessage({ id: "home.btn1" })}
              </PrimaryButton>
              <PrimaryButton
                css={css`
                  @media (max-width: 520px) {
                    max-width: 190.5px;
                  }
                `}
                backgroundColor="var(--clr-primary-200)"
                margin="0 2rem 0 0"
                onClick={() => navigate("/view-my-policy")}
              >
                {intl.formatMessage({ id: "home.btn2" })}
              </PrimaryButton>
              <SecondaryButton
                css={css`
                  @media (max-width: 520px) {
                    max-width: 190.5px;
                  }
                `}
                padding={"1rem 0.9rem"}
                textWeight={"700"}
                backgroundColor="var(--clr-primary-200)"
                margin="0"
                onClick={() => window.location.href = '/view-my-policy'}
              >
                {intl.formatMessage({ id: "renew.title" }).toUpperCase()}
              </SecondaryButton>
            </div>
          </div>
        </article>

        <img
          src={heroImage}
          alt="welcome-to-canada"
          css={css`
            display: none;
            padding-top: 2rem;
            max-height: 309px;

            ${mq[1]} {
              display: block;
              grid-row: 1/1;
              grid-column: 8/-1;
              align-self: flex-start;
            }

            ${mq[2]} {
              padding-top: 0;
            }
          `}
        />
      </div>
    </header>
  )
}

export default Hero

import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useDispatch, connect } from "react-redux"
import { useIntl } from "react-intl"

import { clearQuotes } from "../redux/actions"

const CoveragesDeductibles = ({
  selected,
  data,
  onChange,
  unAvail = [],
  type,
  tugoStudentAvailable
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const [numberData, setNumberData] = useState(data);

  useEffect(() => {
    if (!tugoStudentAvailable) {
      setNumberData(data.filter((d) => +d !== 2000000));
    } else {
      setNumberData(data);
    }
  }, [data, tugoStudentAvailable]);

  const handleSelect = (number) => {
    dispatch(clearQuotes())
    onChange(+number)
  }

  return (
    <div
      css={css`
        margin: 0;
        display: grid;
        grid-template-columns: 135px 1fr;
        grid-template-rows: 1fr;
      `}
    >
      <LabelStyled>
        {intl.formatMessage({
          id: "policies." + type,
        })}
      </LabelStyled>
      <div
        css={css`
          display: grid;
          width: 100%;
          row-gap: 0.5rem;
          grid-template-columns: repeat(auto-fit, 80px);
          column-gap: 0.5rem;
        `}
      >
        {numberData.map(number => (
          <AmountContainer
            key={number}
            active={selected === number}
            grayout={unAvail.includes(number)}
          >
            <p onClick={() => handleSelect(number)}>${new Intl.NumberFormat('en-US', { currency: 'USD' }).format(number)}</p>
          </AmountContainer>
        ))}
      </div>
    </div>
  )
}

const LabelStyled = styled.label`
  font-size: 1.1rem;
  color: var(--clr-primary-300);
  font-family: var(--ff-tertiary);
  font-weight: bold;
  letter-spacing: var(--letter-spacing-sm);
  margin-right: 1rem;
  text-transform: capitalize;
`

const AmountContainer = styled.div`
  width: 80px;
  font-size: 0.8rem;
  font-family: var(--ff-tertiary);
  letter-spacing: var(--letter-spacing-sm);
  text-align: center;
  font-weight: bold;
  display: inline-block;

  ${props =>
    !props.active &&
    !props.grayout &&
    ":hover { p {color: var(--clr-primary-100);border: 1px solid var(--clr-primary-100); }}"}

  p {
    border: 1px solid
      ${props =>
        props.grayout ? "var(--clr-neutral-300)" : "var(--clr-primary-300)"};
    border-radius: var(--border-radius);
    margin: 0 auto;
    width: 100%;
    padding: 0.2rem 0;
    color: ${props =>
      props.active
        ? "var(--clr-neutral-100)"
        : props.grayout
        ? "var(--clr-neutral-300)"
        : "var(--clr-primary-300)"};
    box-shadow: ${props => (props.active ? "none" : "var(--dark-shadow-sb)")};
    cursor: ${props => (props.active ? "default" : "pointer")};
    pointer-events: ${props =>
      props.active || props.grayout ? "none" : "auto"};
    background: ${props =>
      props.active ? "var(--clr-primary-300)" : "var(--clr-neutral-100)"};
    user-select: none;
    transition: var(--transition);
  }
`

const mapStateToProps = ({ allCoveragesDeductibles }) => ({
  allCoveragesDeductibles,
})

export default connect(mapStateToProps)(CoveragesDeductibles)

import axios from "axios"

const BASE_URL = process.env.REACT_APP_API_URL || 'https://api.iwaretravel.ca/'
const QUOTE_BASE_URL = `${BASE_URL}quote/`
const PURCHASE_BASE_URL = `${BASE_URL}purchase/`
const HEALTH_URL = `${BASE_URL}health`
const TOKEN_URL = `${BASE_URL}token`
const COUNTRIES_URL = `${BASE_URL}countries`
const CONDITIONS_URL = `${BASE_URL}conditions`
const GMS_QUOTE_URL = `${QUOTE_BASE_URL}gms`
const TUGO_QUOTE_URL = `${QUOTE_BASE_URL}tugo`
const TUGO_STUDENT_QUOTE_URL = `${QUOTE_BASE_URL}tugoStudent`
const DESTIN_QUOTE_URL = `${QUOTE_BASE_URL}destination`
const CONTACT = `${BASE_URL}contact`

const _axios = axios.create()

export const request = async options => {
  if (!options) return
  // const token = localStorage.getItem("token")
  options.method = options.method || "GET"
  options.headers = options.headers || {}
  // options.headers = Object.assign(options.headers, {
  //   Authorization: `Bearer ${token}`,
  // })
  return _axios(options)
}

export const getHealth = () => request({ url: HEALTH_URL })

export const getToken = () => request({ url: TOKEN_URL })

export const configAuthHeader = token => {
  axios.interceptors.request.use(config => {
    config.headers["Authorization"] = "Bearer " + token

    return config
  })
}

export const getCountries = () => request({ url: COUNTRIES_URL })

export const getConditions = payload => {
  return request({ url: CONDITIONS_URL, data: payload, method: "POST" })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
}

export const getQuoteGMS = payload => {
  return request({ url: GMS_QUOTE_URL, data: payload, method: "POST" })
}
export const getQuoteTugo = payload => {
  return request({ url: TUGO_QUOTE_URL, data: payload, method: "POST" })
}

export const getQuoteTugoStudent = payload => {
  return request({ url: TUGO_STUDENT_QUOTE_URL, data: payload, method: "POST" })
}


export const getQuoteDestin = payload => {
  return request({ url: DESTIN_QUOTE_URL, data: payload, method: "POST" })
}

export const purchaseInsurerPlan = (payload, insurer) => {
  return request({
    url: `${PURCHASE_BASE_URL}${insurer}`,
    data: payload,
    method: "POST",
  })
}

export const getPolicy = (type, term) => {
  const searchURL = `${BASE_URL}search?${type}=${term}`
  return request({
    url: searchURL,
  })
}

export const sendContact = payload => {
  return request({
    url: CONTACT,
    data: payload,
    method: "POST",
  })
}

import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { useIntl } from "react-intl"

import { CardContainerStyled, InputWithLabelSessionStorage, DropWithLabelSessionStorage } from "."
import { provinceCode, validGMSProvincesForPurchase } from "../utils/provinces"

const PaymentInfoCard = ({ paymentInfoItems }) => {
  const intl = useIntl()

  const paymentInfoValidation = {
    email: [
      {
        validateFunc: email => email.match(/\S+@\S+\.\S+/) !== null,
        noneEligibleMessage: intl.formatMessage({ id: "error.validEmail" }),
      },
    ],
    province: [
      {
        validateFunc: province =>
          validGMSProvincesForPurchase.includes(province.toUpperCase()),
        noneEligibleMessage: intl.formatMessage({ id: "error.province" }),
      },
    ],
  }

  return (
    <CardContainerStyled
      maxWidth="700px"
      css={css`
        margin: 0 auto;
        text-transform: none;
      `}
    >
      <Wrapper>
        <div className="innerWrapper">
          {Object.keys(paymentInfoItems).map(item =>
            item !== "province" ? (
              <>
                <InputWithLabelSessionStorage
                  key={item}
                  label={paymentInfoItems[item].label}
                  sessionStorageKey={item}
                  required={!["wechat", "studentNumber"].includes(item)}
                  nonEmpty={paymentInfoItems[item].nonEmpty}
                  validateVal={
                    item === "confirmEmail"
                      ? {
                          ...paymentInfoValidation,
                          confirmEmail: [
                            {
                              validateFunc: confirmEmail => {
                                const prevEmail =
                                  typeof window !== "undefined" &&
                                  window.sessionStorage.getItem("email")
                                return prevEmail === confirmEmail
                              },
                              noneEligibleMessage: intl.formatMessage({
                                id: "error.emailMatch",
                              }),
                            },
                          ],
                        }
                      : paymentInfoValidation
                  }
                  labelFontWeight="normal"
                  inputFontWeight="bold"
                  labelFontSize="0.875rem"
                  inputTextTransform="none"
                  isTextArea={paymentInfoItems[item].textArea}
                  placeholder={item === "phone" ? "000-000-0000" : ""}
                  help={paymentInfoItems[item]?.help}
                />
              </>
            ) : (
              <DropWithLabelSessionStorage
                label={paymentInfoItems[item].label}
                dataArr={Object.keys(provinceCode).filter(p => p !== "Quebec-Remove")}
                labelFontWeight="normal"
                inputFontWeight="bold"
                labelFontSize="0.875rem"
                required
                sessionStorageKey={item}
                nonEmpty={paymentInfoItems[item].nonEmpty}
                key={item}
              />
            )
          )}
        </div>
      </Wrapper>
    </CardContainerStyled>
  )
}

const Wrapper = styled.div`
  .innerWrapper {
    padding: 2rem 4rem;
    display: grid;
    row-gap: 1rem;
    column-gap: 4rem;

    @media (min-width: 605px) {
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }
  }
`

export default PaymentInfoCard

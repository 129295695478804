import ACTIONS from "../actions/actionTypes"

const initAvail = {
  gms: { avail: true, quoteNotAvailMessage: null },
  tugo: { avail: true, quoteNotAvailMessage: null },
  tugoStudent: { avail: true, quoteNotAvailMessage: null },
  destination: { avail: false, quoteNotAvailMessage: null },
}

export const insurerAvail = (state = initAvail, action) => {
  const { type, payload } = action
  if (type === ACTIONS.SET_INSURE_AVAIL) return payload
  return state
}

export const preExisting = (state = true, action) => {
  const { type, payload } = action
  if (type === ACTIONS.SET_PRE_EXISTING) return payload
  return state
}

export const famRate = (
  state = { destination: false, tugo: false, tugoStudent: false },
  action
) => {
  const { type, payload } = action
  if (type === ACTIONS.SET_IS_FAM_RATE) return payload
  return state
}

const provinces = [
  { name: "Newfoundland and Labrador", code: "NL" },
  // { name: "Prince Edward Island", code: "PE" },
  { name: "Nova Scotia", code: "NS" },
  // { name: "New Brunswick", code: "NB" },
  { name: "Quebec", code: "QC" },
  { name: "Ontario", code: "ON" },
  // { name: "Manitoba", code: "MB" },
  { name: "Saskatchewan", code: "SK" },
  { name: "Alberta", code: "AB" },
  { name: "British Columbia", code: "BC" },
  // { name: "Yukon", code: "YT" },
  // { name: "Northwest Territories", code: "NT" },
  // { name: "Nunavut", code: "NU" }
].sort((a, b) => (a.name > b.name ? 1 : a.name === b.name ? 0 : -1))

export const provinceCode = {
  Alberta: "AB",
  "British Columbia": "BC",
  Manitoba: "MB",
  "Northwest Territories": "NT",
  Saskatchewan: "SK",
  Ontario: "ON",
  "Nova Scotia": "NS",
  "Newfoundland and Labrador": "NL",
  Nunavut: "NU",
  Quebec: "QC",
  "Prince Edward Island": "PE",
  Yukon: "YT"
}

export const validGMSProvincesForPurchase = [
  "AB",
  "BC",
  "MB",
  "NL",
  "NS",
  "NT",
  "NU",
  "ON",
  "PE",
  "SK",
  "YT",
]

export default provinces

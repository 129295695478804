const defaultActions = {
  SET_LOCALE: "SET_LOCALE",
  GET_CURRENT_PATH: "GET_CURRENT_PATH",
  RECORD_PURPOSE: "RECORD_PURPOSE",
  SET_TRAVEL_PLAN: "SET_TRAVEL_PLAN",
  DELETE_TRAVEL_PLAN: "DELETE_TRAVEL_PLAN",
  SET_TRAVELLERS: "SET_TRAVELLERS",
  DELETE_TRAVELLER: "DELETE_TRAVELLER",
  DELETE_TRAVELLERS: "DELETE_TRAVELLERS",
  IF_CAN_PROCEED: "IF_CAN_PROCEED",
  BEGIN_API_CALL: "BEGIN_API_CALL",
  API_CALL_ERROR: "API_CALL_ERROR",
  API_CALL_END: "API_CALL_END",
  GET_API_HEALTH: "GET_API_HEALTH",
  GET_COUNTRIES: "GET_COUNTRIES",
  GET_CONDITIONS: "GET_CONDITIONS",
  GET_QUOTE_GMS: "GET_QUOTE_GMS",
  GET_QUOTE_TUGO: "GET_QUOTE_TUGO",
  GET_QUOTE_TUGO_STUDENT: "GET_QUOTE_TUGO_STUDENT",
  GET_QUOTE_DESTIN: "GET_QUOTE_DESTIN",
  SET_TOKEN_EXPIRY_TIME: "SET_TOKEN_EXPIRY_TIME",
  SET_COVERAGE: "SET_COVERAGE",
  SET_DEDUCTIBLE: "SET_DEDUCTIBLE",
  SET_UNAVAILABLE_COVERAGES: "SET_UNAVAILABLE_COVERAGES",
  SET_UNAVAILABLE_DEDUCTIBLES: "SET_UNAVAILABLE_DEDUCTIBLES",
  SET_ALL_COVERAGES_DEDUCTIBLES: "SET_ALL_COVERAGES_DEDUCTIBLES",
  SET_INSURE_AVAIL: "SET_INSURE_AVAIL",
  SET_PRE_EXISTING: "SET_PRE_EXISTING",
  CLEAR_QUOTES: "CLEAR_QUOTES",
  SET_QUOTES_LOADED: "SET_QUOTES_LOADED",
  SET_COMPARE_INSURER: "SET_COMPARE_INSURER",
  CLEAR_COMPARE_INSURER: "CLEAR_COMPARE_INSURER",
  SET_SELECTED_PLAN: "SET_SELECTED_PLAN",
  CLEAR_SELECTED_PLAN: "CLEAR_SELECTED_PLAN",
  SET_PLAN_ELIGIBILITY: "SET_ELIGIBILITY",
  CLEAR_ELIGIBILITY: "CLEAR_ELIGIBILITY",
  SET_PLAN_LOCATION: "SET_LOCATION",
  CLEAR_PLAN_LOCATION: "CLEAR_LOCATION",
  SET_IN_CA_SITUATION: "SET_IN_CA_SITUATION",
  CLEAR_IN_CA_SITUATION: "CLEAR_IN_CA_SITUATION",
  SET_CURRENTPOLICY: "SET_CURRENTPOLICY",
  CLEAR_CURRENTPOLICY: "CLEAR_CURRENTPOLICY",
  SET_USER_FINAL_CONFIRMATION: "SET_USER_FINAL_CONFIRMATION",
  CLEAR_ALL_PURCHASECONFIRMATION: "CLEAR_ALL_PURCHASECONFIRMATION",
  SET_PAYMENT_INFO: "SET_PAYMENT_INFO",
  MAKE_PURCHASE: "MAKE_PURCHASE",
  CLEAR_PURCHASE_RESULT: "CLEAR_PURCHASE_RESULT",
  GET_POLICY: "GET_POLICY",
  CLEAR_POLICY: "CLEAR_POLICY_SEARCH",
  SET_OPTIONS: "SET_OPTIONS",
  DELETE_OPTION: "DELETE_OPTION",
  DELETE_ALL_SPORT_OPTIONS: "DELETE_ALL_SPORT_OPTIONS",
  SET_IS_FAM_RATE: "SET_IS_FAM_RATE",
  SEND_CONTACT: "SEND_CONTACT"
}

export const quotesActions = {
  gms: defaultActions.GET_QUOTE_GMS,
  tugo: defaultActions.GET_QUOTE_TUGO,
  tugoStudent: defaultActions.GET_QUOTE_TUGO_STUDENT,
  destination: defaultActions.GET_QUOTE_DESTIN,
}

export default defaultActions

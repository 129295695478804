import React, { useState, useEffect, useRef } from "react"
import { css } from "@emotion/core"
import { GrFormClose } from "react-icons/gr"
import { useDispatch, useSelector } from "react-redux"
import { useIntl } from "react-intl"

import {
  InputWithLabelSimple,
  LabelStyled,
  CardContainerStyled,
  RadioWithLabelSimple,
  DropdownSimple,
} from "."
import ACTIONS from "../redux/actions/actionTypes"
import { mq } from "../utils/media-query"
import {
  validDate,
  policyholderValidDate,
  all,
  dependantValidDate,
} from "../utils/date-validations"
import { is60yrsOrOlder, at21OrYounger } from "../utils/time-related-func"

const TravellersCard = ({ traveller }) => {
  const intl = useIntl()
  const [focused, setFocused] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const [invalidDependantAge, setInvalidDependantAge] = useState(false)
  const refContainer = useRef(null)
  const dispatch = useDispatch()
  const [isCardMounted, setIsCardMounted] = useState(false)
  const travellers = useSelector(state => state.travellers)
  const travellerFromState = travellers.find(t => t.id === traveller.id)

  useEffect(() => {
    setIsCardMounted(true)
    return () => {
      setIsCardMounted(false)
    }
  }, [])

  useEffect(() => {
    if (
      travellerFromState.relationship_status === "dependant" &&
      travellerFromState.birth_date
    ) {
      if (!at21OrYounger(travellerFromState.birth_date)) {
        setInvalidDependantAge(true)
      }
    }
    return () => {
      setInvalidDependantAge(false)
    }
  }, [travellerFromState])

  useEffect(() => {
    const handleFocusByClick = e => {
      if (!isCardMounted) return
      if (refContainer.current && !refContainer.current.contains(e.target)) {
        setFocused(false)
      } else {
        setFocused(true)
      }
    }
    // check if relationship_status is filled
    const handleValidByMouseLeave = e => {
      if (!isCardMounted) return

      if (
        travellerFromState.first_name &&
        travellerFromState.last_name &&
        travellerFromState.gender &&
        travellerFromState.birth_date &&
        !travellerFromState.relationship_status
      ) {
        setInvalid(true)
      }
    }

    const cancelInvalidByMouseEnter = e => {
      setInvalid(false)
    }

    document.addEventListener("click", handleFocusByClick)
    refContainer.current &&
      refContainer.current.addEventListener(
        "mouseleave",
        handleValidByMouseLeave
      )
    refContainer.current &&
      refContainer.current.addEventListener(
        "mouseenter",
        cancelInvalidByMouseEnter
      )
    return () => {
      document.removeEventListener("click", handleFocusByClick)
      refContainer.current &&
        refContainer.current.removeEventListener(
          "mouseleave",
          handleValidByMouseLeave
        )
      refContainer.current &&
        refContainer.current.removeEventListener(
          "mouseenter",
          cancelInvalidByMouseEnter
        )
    }
  }, [refContainer, isCardMounted, traveller])

  const onChangeVal = (field, val) => {
    let ageCheck = {}
    if (field === "birth_date")
      ageCheck["is60yrsOrOlder"] = is60yrsOrOlder(val) ? "y" : "n"

    dispatch({
      type: ACTIONS.SET_TRAVELLERS,
      payload: Object.assign({}, traveller, { [field]: val }, ageCheck),
    })
  }

  const onDeleteTraveller = traveller => {
    dispatch({
      type: ACTIONS.DELETE_TRAVELLER,
      payload: traveller,
    })
  }

  return (
    <CardContainerStyled
      ref={refContainer}
      css={css`
        position: relative;
        transition: var(--transition);

        ${focused &&
        "border: 2px solid var(--clr-primary-100);box-shadow: var(--solid-shadow-primary100);"}

        @media (min-width: 800px) {
          width: 100%;
          margin: 0 auto;
        }
        ${mq[1]} {
          width: 90%;
        }
      `}
    >
      {traveller.relationship_status !== "policyholder" && (
        <GrFormClose
          css={css`
            position: absolute;
            right: 1rem;
            top: 1rem;
            font-size: 2rem;
            color: var(--clr-primary-300);
            cursor: pointer;
          `}
          onClick={() => {
            onDeleteTraveller(traveller)
          }}
        />
      )}
      <div
        css={css`
          padding: 2rem 2rem 4rem 2rem;
          display: grid;
          row-gap: 1.5rem;
          width: 90%;
          margin: 0 auto;
          justify-content: center;

          @media (min-width: 800px) {
            grid-template-columns: 1fr 1fr;
            align-items: flex-start;
            column-gap: 3rem;
            justify-items: center;
            width: 90%;
          }
        `}
      >
        <LabelStyled
          css={css`
            max-width: 20rem;
            padding: 1rem 0;
            font-size: 1.5rem;
            margin-bottom: -1rem;
            justify-self: start;

            @media (min-width: 500px) {
              width: 20rem;
            }
            @media (min-width: 800px) {
              margin: 0 auto;
            }
          `}
        >
          {intl.formatMessage({
            id: traveller.relationship_status || "generic.traveller",
          })}
        </LabelStyled>
        {traveller.relationship_status === "policyholder" ? (
          <div></div>
        ) : (
          <DropdownSimple
            filterValue={traveller.relationship_status || ''}
            onChangeOpt={val => onChangeVal("relationship_status", val)}
            dataArr={
              travellers.find(t => t.relationship_status === "spouse")
                ? ["dependant", "other"]
                : ["spouse", "dependant", "other"]
            }
            invalid={invalid}
            initPH={intl.formatMessage({
              id: "relationship.placeholder",
            })}
          />
        )}
        <div
          css={css`
            width: 100%;
            height: 1px;
            background-color: var(--clr-neutral-300);
            margin-bottom: 2rem;
            grid-column: 1 / -1;
          `}
        ></div>
        <InputWithLabelSimple
          label={intl.formatMessage({
            id: "travellers.holderfname",
          })}
          required
          inputFromParent={traveller.first_name}
          errorMessage={intl.formatMessage({ id: "error.fname" })}
          onChangeVal={val => onChangeVal("first_name", val)}
        />
        <InputWithLabelSimple
          label={intl.formatMessage({
            id: "travellers.holderlname",
          })}
          required
          inputFromParent={traveller.last_name}
          errorMessage={intl.formatMessage({ id: "error.lname" })}
          onChangeVal={val => onChangeVal("last_name", val)}
        />
        <InputWithLabelSimple
          label={intl.formatMessage({
            id: "travellers.holderdob",
          })}
          placeholder="YYYY-MM-DD"
          required
          inputFromParent={traveller.birth_date}
          onChangeVal={val => onChangeVal("birth_date", val)}
          validateVal={
            travellerFromState.relationship_status === "policyholder"
              ? [...validDate, ...policyholderValidDate, ...all]
              : travellerFromState.relationship_status === "dependant"
              ? [...validDate, ...dependantValidDate, ...all]
              : [...validDate, ...all]
          }
          invalidDependantAge={invalidDependantAge}
          invalidDependantAgeMessage="dependantAge"
        />
        <RadioWithLabelSimple
          label={intl.formatMessage({
            id: "travellers.holdergender",
          })}
          name={`${traveller.id}-gender`}
          inputFromParent={traveller.gender}
          onChangeVal={val => onChangeVal("gender", val)}
          options={[
            intl.formatMessage({
              id: "female",
            }),
            intl.formatMessage({
              id: "male",
            }),
          ]}
          required
          wordsMapping={{
            [intl.formatMessage({
              id: "female",
            })]: "female",
            [intl.formatMessage({
              id: "male",
            })]: "male",
          }}
        />
      </div>
    </CardContainerStyled>
  )
}

export default TravellersCard

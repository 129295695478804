import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import { LabelStyled } from "."
import { mq } from "../utils/media-query"

const sampleData = [
  {
    subCap: "Age 0 to 85",
    content: [
      "If the insurance was purchased prior to your arrival date in Canada, the “waiting period” does not apply or,",
      "If this insurance is purchased any time after your arrival in Canada, then in respect of any sickness you will only be entitled to receive benefits for the cost of eligible medical expenses incurred after the first 48 hours from the effective date of the policy.",
    ],
  },
]

const InfoCard = ({
  label = "Default label:",
  data = sampleData,
  color,
  className,
  fontSize,
  labelTextAlign,
  labelBtmMargin,
}) => {
  return (
    <div className={className}>
      <LabelStyled
        textTransform="none"
        marginBottom={labelBtmMargin || "0.75rem"}
        textAlign={labelTextAlign}
      >
        {label}
      </LabelStyled>
      <Wrapper
        gridCol={`${data.reduce(acc => {
          acc += "1fr "
          return acc
        }, "")}`}
      >
        {data.map((dataGroup, idx) => (
          <Container
            key={idx}
            borderColor={color}
            shadowColor={color}
            fontSize={fontSize}
          >
            {dataGroup.subCap && <p className="subCap">{dataGroup.subCap}</p>}
            {dataGroup.content.map((dataPiece, index) => {
              return (
                <p 
                  className={index % 2 === 0 ? "even" : "odd"} 
                  key={index}
                  dangerouslySetInnerHTML={{ __html: dataPiece }}
                />
              )
            })}
          </Container>
        ))}
      </Wrapper>
    </div>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  column-gap: 1.25rem;
  row-gap: 1rem;
  margin-bottom: 2.5rem;

  ${[mq[0]]} {
    grid-template-columns: ${props => props.gridCol};
  }
`

const Container = styled.div`
  border-radius: var(--border-radius-md);
  border: solid 1px ${props => props.borderColor || "var(--clr-primary-300)"};
  box-shadow: 0.2rem 0.2rem 0 0
    ${props => props.shadowColor || "var(--clr-primary-300)"};
  font-family: var(--ff-quaternary);
  font-size: ${props => props.fontSize || "1rem"};

  .even {
    background-color: var(--clr-neutral-200lighter);
  }

  .subCap {
    font-weight: bold;
  }

  p {
    margin: 0;
    padding: 1.25rem 1.5rem;

    :last-of-type {
      border-bottom-right-radius: var(--border-radius-md);
      border-bottom-left-radius: var(--border-radius-md);
    }

    :first-of-type {
      border-top-right-radius: var(--border-radius-md);
      border-top-left-radius: var(--border-radius-md);
    }

    span {
      display: block;
      :not(:first-of-type) {
        margin-left: 1.5rem;
      }
    }
  }
`

export default InfoCard

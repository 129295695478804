export default {
  names: [
    "all coverages",
    "all deductibles",
    "age limit",
    "Covid-19 coverage",
    "drugs or medications",
    "emergency hospital",
    "Paramedical Services",
    "follow-up visits",
    "Repatriation",
    "dental emergency",
    "Accidental Death & Dismemberment",
    "Waiting period",
    "Pre-existing medical conditions",
    "Pre-existing conditions coverage age limit",
    "Maternity",
    "Optional non-medical coverage",
  ],
  destination: {
    "all coverages":
      "$10,000 - $25,000 - $50,000 - $100,000 - $150,000 or $300,000",
    "all deductibles": "$0 - $250 - $500 - $1,000 - S2,500 - $5,000 - $10,000",
    "age limit": "None",
    "Covid-19 coverage": "Yes, but side trips not covered",
    "drugs or medications": "up to $1,000 not exceeding 30-day supply",
    "emergency hospital": "semi-private",
    "Paramedical Services": "up to $500 per practitioner",
    "follow-up visits": "Up to CAD3,000",
    Repatriation: "Up to $3,000 for a one-way economy transportation",
    "return of deceased":
      "Up to $10,000 to return the remains  or $4,000 for cremation or burial",
    "dental emergency":
      "Up to CAD3,000 for accidental blow to the face, up to CAD500 for relief of dental pain",
    "Accidental Death & Dismemberment":
      "Up to the maximum sum of insured not to exceed of CAD150,000. Flight accident up to $50,000.",
    "Waiting period":
      "If purchased after arrival in Canada, 48 hours applicable to sickness (age 0 to 85). 15 days (age 86 and over)",
    "Pre-existing medical conditions":
      "For 0 to 79, who chooses the Option with pre-existing coverage, 120 days stable period",
    "Pre-existing conditions coverage age limit": "Up to 79 years old",
    Maternity: "None",
    "Optional non-medical coverage": "None",
  },
  gms: {
    "all coverages": "$25,000 - $50,000 - $100,000 - $150,000",
    "all deductibles": "$0 - $100 - $500 - $1,000",
    "age limit": "Under 80 years old",
    "Covid-19 coverage": "None",
    "drugs or medications": "Up to a 30 day prescription",
    "emergency hospital": "semi-private",
    "Paramedical Services": "up to $300",
    "follow-up visits":
      "Covered until the medical emergency has been deemed to have ended",
    Repatriation: "Up to $5,000 to transport you home",
    "return of deceased":
      "Up to $10,000 to return the remains  or $4,000 for cremation or burial",
    "dental emergency":
      "Up to $2,000 per person and up to $250 for the relief of dental pain",
    "Accidental Death & Dismemberment": "N/A",
    "Waiting period":
      "If purchased within 30 days of arrival, 2 day waiting period. If purchased more than 30 days of arrival, 7 day waiting period",
    "Pre-existing medical conditions":
      "Covered stable conditions during 180 days prior to the effective date",
    "Pre-existing conditions coverage age limit": "Up to 79 years old",
    Maternity: "None",
    "Optional non-medical coverage": "None",
  },
  tugo: {
    "all coverages":
      "$10,000 - $25,000 - $50,000 - $100,000 - $200,000 or $300,000",
    "all deductibles": "$0 - $150 - $500 - $1,000 - $2,500 - S5,000 - $10,000",
    "age limit": "None",
    "Covid-19 coverage": "Yes, and side trips covered",
    "drugs or medications": "Up to a 30-day supply",
    "emergency hospital": "semi-private",
    "Paramedical Services": "Up to $600 per practitioner",
    "follow-up visits": "Up to 5 follow up visits within 14 days",
    Repatriation:
      "Up to the sum insured for preparation and return of the body",
    "return of deceased":
      "Up to $6,000 for burial or cremation at the place of death",
    "dental emergency":
      "Up to $6,000 for accidental blow to the face, Up to $600 for any other dental emergencies",
    "Accidental Death & Dismemberment":
      "Air flight/common carrier up to $100,000. 24-hour accident up to $25,000",
    "Waiting period":
      "If purchased within 60 days of arrival, 48-hour waiting period on sickness. 7-day if purchased 61 days or more after arrival",
    "Pre-existing medical conditions":
      "For 59 years and under, 120 days stable period. for 60 to 69 years, 180 days stable period. For 70 above, 365 days stable period",
    "Pre-existing conditions coverage age limit": "None",
    Maternity:
      "Up to $6,000 for pre-natal care, delivery and/or complications thereof",
    "Optional non-medical coverage": "Sports and activities coverage",
  },
  tugoStudent: {
    "all coverages":
      "$10,000 - $25,000 - $50,000 - $100,000 - $200,000 or $300,000",
    "all deductibles": "$0 - $150 - $500 - $1,000 - $2,500 - S5,000 - $10,000",
    "age limit": "None",
    "Covid-19 coverage": "Yes, and side trips covered",
    "drugs or medications": "Up to a 30-day supply",
    "emergency hospital": "semi-private",
    "Paramedical Services": "Up to $600 per practitioner",
    "follow-up visits": "Up to 5 follow up visits within 14 days",
    Repatriation:
      "Up to the sum insured for preparation and return of the body",
    "return of deceased":
      "Up to $6,000 for burial or cremation at the place of death",
    "dental emergency":
      "Up to $6,000 for accidental blow to the face, Up to $600 for any other dental emergencies",
    "Accidental Death & Dismemberment":
      "Air flight/common carrier up to $100,000. 24-hour accident up to $25,000",
    "Waiting period":
      "If purchased within 60 days of arrival, 48-hour waiting period on sickness. 7-day if purchased 61 days or more after arrival",
    "Pre-existing medical conditions":
      "For 59 years and under, 120 days stable period. for 60 to 69 years, 180 days stable period. For 70 above, 365 days stable period",
    "Pre-existing conditions coverage age limit": "None",
    Maternity:
      "Up to $6,000 for pre-natal care, delivery and/or complications thereof",
    "Optional non-medical coverage": "Sports and activities coverage",
  }
}

import ACTIONS from "../actions/actionTypes"
import moment from "moment"

// Tugo api I/O docs: https://developer.tugo.com/io-docs
// Answers to questionnaire questions can be: -1, 0, 1.
// -1.0 Not Answered, 0.0 Deselected, 1.0 Selected. Default value is -1.0

const debugDomain = 'codekeeper';
const initState = [
  {
    first_name: typeof (window) !== 'undefined' && window.location.host.includes(debugDomain) ? "Test" : '',
    last_name: typeof (window) !== 'undefined' && window.location.host.includes(debugDomain) ? "Me" : '',
    birth_date: typeof (window) !== 'undefined' && window.location.host.includes(debugDomain) ? '1981-12-06' : '',
    relationship_status: "policyholder",
    gender: typeof (window) !== 'undefined' && window.location.host.includes(debugDomain) ? 'male' : '',
    is60yrsOrOlder: typeof (window) !== 'undefined' && window.location.host.includes(debugDomain) ? 'n' : '',
    id: "policyholder",
    options: [{ code: "include-pre-existing-coverage" }],
    mq: {
      code: "QU-VMQ-1",
      questions: [
        {
          code: "QT-VIS-1",
          answers: [
            {
              code: "q1a1",
              value: -1,
            },
            {
              code: "q1a2",
              value: -1,
            },
            {
              code: "q1a3",
              value: -1,
            },
            {
              code: "q1a4",
              value: -1,
            },
          ],
        },
        {
          code: "QT-VIS-2",
          answers: [
            {
              code: "q2a1",
              value: -1,
            },
            {
              code: "q2a2",
              value: -1,
            },
            {
              code: "q2a3",
              value: -1,
            },
            {
              code: "q2a4",
              value: -1,
            },
            {
              code: "q2a5",
              value: -1,
            },
            {
              code: "q2a6",
              value: -1,
            },
            {
              code: "q2a7",
              value: -1,
            },
            {
              code: "q2a8",
              value: -1,
            },
            {
              code: "q2a9",
              value: -1,
            },
          ],
        },
        {
          code: "QT-VIS-3",
          answers: [
            {
              code: "q3a1",
              value: -1,
            },
            {
              code: "q3a2",
              value: -1,
            },
          ],
        },
      ],
    },
  },
]

export const travellers = (state = initState, action) => {
  const payload = action.payload
  if (action.type === ACTIONS.SET_TRAVELLERS) {
    if (state.find(el => el.id === payload.id)) {
      return state.map(traveller => {
        if (traveller.id === payload.id) {
          return Object.assign({}, traveller, payload)
        }
        return traveller
      })
    }
    return [...state, payload]
  }
  if (action.type === ACTIONS.DELETE_TRAVELLER) {
    if (state.find(el => el.id === payload.id)) {
      return state.filter(traveller => traveller.id !== payload.id)
    }
    return state
  }
  if (action.type === ACTIONS.DELETE_TRAVELLERS) {
    return initState
  }
  return state
}

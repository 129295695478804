import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useSelector, useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import {
  PrimaryButton,
  SecondaryButton,
  RadioWithLabel,
  CardContainerStyled,
  InputWithLabel,
  DateDropdown,
  LabelStyled,
} from ".."
import ACTIONS from "../../redux/actions/actionTypes"

const InCAFollowUp = ({ onProceed, onGoBack, gmsWording }) => {
  const intl = useIntl()
  const wordMapping = {
    [intl.formatMessage({
      id: "gms.inCA1",
    })]: "I am purchasing this plan to replace my current Canadian health insurance plan.",
    [intl.formatMessage({
      id: "gms.inCA2",
    })]: "I have been in Canada for LESS than 30 days and am not currently covered by a Canadian health insurance plan.",
    [intl.formatMessage({
      id: "gms.inCA3",
    })]: "I have been in Canada for MORE than 30 days and am not currently covered by a Canadian health insurance plan.",
  }
  const dispatch = useDispatch()
  const purchaseInfo = useSelector(state => state.purchaseConfirmation)
  const canProceedFromUI = useSelector(state => state.canProceedFromUI)
  const situation = useSelector(state => state.purchaseConfirmation.situation)
  const unableToContinue = () => {
    if (!purchaseInfo.situation) return true
    if (purchaseInfo.situation.includes("replace")) {
      if (
        !purchaseInfo.currentPolicy.endDate ||
        !purchaseInfo.currentPolicy.company ||
        !purchaseInfo.currentPolicy.policyNumber
      )
        return true
    }
    // if (canProceedFromUI !== null && !canProceedFromUI) return true
    return false
  }
  const onProceedNext = () => {
    if (unableToContinue()) return
    onProceed()
  }

  useEffect(() => {
    if (!situation || !situation.includes("replace")) {
      dispatch({
        type: ACTIONS.CLEAR_CURRENTPOLICY,
      })
    }
  }, [situation])

  return (
    <Wrapper>
      <RadioWithLabel
        name={`inCAFollowUp`}
        actionType={ACTIONS.SET_IN_CA_SITUATION}
        secondLevelStatePropToChange="situation"
        firstLevelStatePropToChange="purchaseConfirmation"
        options={gmsWording.inCAFollowUp}
        required
        id={`inCAFollowUp`}
        radioDisplay="grid"
        radioWidth="100%"
        itemMargin="0 0 2rem 0"
        rightMargin="1.5rem"
        extraDisplayItems={extraDisplayItems}
        wordMapping={wordMapping}
      />
      <div className="btnWrapper">
        <PrimaryButton
          textSize="0.875rem"
          textTransform="capitalize"
          maxWidth="none"
          margin="0 4rem 0 0"
          onClick={onProceedNext}
        >
          {intl.formatMessage({ id: "btn.continue" })}
        </PrimaryButton>
        <SecondaryButton
          buttonColor="var(--clr-neutral-400)"
          textSize="0.875rem"
          margin="none"
          onClick={onGoBack}
        >
          {intl.formatMessage({ id: "btn.goback" })}
        </SecondaryButton>
      </div>
    </Wrapper>
  )
}

const extraDisplayItems = [
  () => <CurrentHealthPlan />,
  () => {
    const intl = useIntl()
    return (
      <CardContainerStyled className="extraDisplayItem">
        <p className="textDisplay">
          {intl.formatMessage({ id: "gms.inCA.lessThan30" })}
        </p>
      </CardContainerStyled>
    )
  },
  () => {
    const intl = useIntl()
    return (
      <CardContainerStyled className="extraDisplayItem">
        <p className="textDisplay">
          {intl.formatMessage({ id: "gms.inCA.moreThan30" })}
        </p>
      </CardContainerStyled>
    )
  },
]

const CurrentHealthPlan = () => {
  const intl = useIntl()
  return (
    <CardContainerStyled className="extraDisplayItem">
      <div
        css={css`
          padding: 1.5rem 1.5rem 2.5rem 1.5rem;
          display: grid;
          row-gap: 1.5rem;
          width: 90%;
          margin: 0 auto;
          justify-content: center;
          justify-items: center;

          @media (min-width: 800px) {
            grid-template-columns: 1fr 1fr;
            align-items: flex-start;
            column-gap: 1.5rem;
            width: 90%;
          }
        `}
      >
        <LabelStyled
          css={css`
            padding: 1rem 0;
            font-size: 1.25rem;
            margin-bottom: -1rem;
            text-align: center;

            @media (min-width: 500px) {
              width: 100%;
              grid-column: 1/-1;
            }
            @media (min-width: 800px) {
              margin: 0 auto;
            }
          `}
        >
          {intl.formatMessage({ id: "gms.inCA.replace.cap" })}
        </LabelStyled>
        <div className="textCenter">
          <InputWithLabel
            label={intl.formatMessage({ id: "gms.inCA.replace.company" })}
            secondLevelStatePropToChange="currentPolicy"
            firstLevelStatePropToChange="purchaseConfirmation"
            thirdLevelStatePropToChange="company"
            actionType={ACTIONS.SET_CURRENTPOLICY}
            required
            errorMessage={intl.formatMessage({ id: "error.entername" })}
            labelFontSize="0.875rem"
            wordFontSize="0.875rem"
            inputMinWidth="20rem"
          />
        </div>
        <div className="textCenter">
          <InputWithLabel
            label={intl.formatMessage({ id: "gms.inCA.replace.policyNum" })}
            secondLevelStatePropToChange="currentPolicy"
            firstLevelStatePropToChange="purchaseConfirmation"
            thirdLevelStatePropToChange="policyNumber"
            actionType={ACTIONS.SET_CURRENTPOLICY}
            required
            errorMessage={intl.formatMessage({ id: "error.enterPolicyNum" })}
            labelFontSize="0.875rem"
            wordFontSize="0.875rem"
            inputMinWidth="20rem"
          />
        </div>
        <div className="expiryDate">
          <DateDropdown
            label={intl.formatMessage({ id: "gms.inCA.replace.expiryDate" })}
            secondLevelStatePropToChange="currentPolicy"
            firstLevelStatePropToChange="purchaseConfirmation"
            thirdLevelStatePropToChange="endDate"
            readOnly
            labelFontSize="0.875rem"
            actionType={ACTIONS.SET_CURRENTPOLICY}
          />
        </div>
      </div>
    </CardContainerStyled>
  )
}

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;

  .btnWrapper {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .extraDisplayItem {
    margin: 1rem 0;
  }

  .expiryDate {
    grid-column: 1/-1;
  }

  .textDisplay {
    text-transform: none;
    padding: 1rem 1.25rem;
    font-size: 0.875rem;
    margin: 0;

    span {
      font-weight: bold;
    }
  }
`

export default InCAFollowUp

import ACTIONS from "../actions/actionTypes"
import moment from "moment"

const debugDomain = 'codekeeper';
const initState = {
  travelFrom: typeof (window) !== 'undefined' && window.location.host.includes(debugDomain) ? "China" : '',
  travelTo: typeof (window) !== 'undefined' && window.location.host.includes(debugDomain) ? "Alberta" : '',
  startDate: typeof (window) !== 'undefined' && window.location.host.includes(debugDomain) ? moment('2023-11-01') : null,
  endDate: typeof (window) !== 'undefined' && window.location.host.includes(debugDomain) ? moment('2023-11-30') : null,
  arrivalDate: typeof (window) !== 'undefined' && window.location.host.includes(debugDomain) ? moment('2023-11-01') : null,
  applicationDate: moment().format("YYYY-MM-DD"),
  purpose: typeof (window) !== 'undefined' && window.location.host.includes(debugDomain) ? "Outdoor Adventure" : ''
}

export const travelPlan = (state = initState, action) => {
  if (action.type === ACTIONS.SET_TRAVEL_PLAN)
    return {
      ...state,
      ...action.payload,
    }
  if (action.type === ACTIONS.DELETE_TRAVEL_PLAN)
    return initState
  return state
}

import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { useSelector, useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import { IoMdInformationCircle } from "react-icons/all"
import { sportsCoverageOptions, tugoTripInterruption } from "../utils/quotesOptions"
import { getAge } from "../utils/time-related-func"
import ACTIONS from "../redux/actions/actionTypes"
import { InfoDialogue } from ".";
import { css } from "@emotion/core"

const TugoExtraOptions = ({ onChangeOpt, quotes }) => {
  const intl = useIntl()

  let coverageOptions = Object.keys(sportsCoverageOptions)
  let tripInterruptionOptions = Object.keys(tugoTripInterruption);
  const dispatch = useDispatch()

  const travellers = useSelector(state => state.travellers)
  const optionsFromState = useSelector(state => state.options) || []

  const initCoverageNeedCheck = () => {
    for (const opt of optionsFromState) {
      if (
        Object.keys(sportsCoverageOptions).find(
          o => sportsCoverageOptions[o].code === opt.code
        )
      )
        return "Yes"
    }
    return "No"
  }

  const [needCover, setNeedCover] = useState(initCoverageNeedCheck())
  const [needTripInterruptionCancellation, setNeedTripInterruptionCancellation] = useState('No');
  const [needTripInterruption, setNeedTripInterruption] = useState('No');
  const [tripInterruptionValue, setTripInterruptionValue] = useState(1500);
  const [tripCancellationInterruptionValue, setTripCancellationInterruptionValue] = useState(1000);
  const [showInfo, setShowInfo] = useState({
    tripInterruption: undefined,
    tripCancellationInterruption: undefined
  });

  useEffect(() => {
    if (quotes?.tugo?.price?.tripCancelationInterruptionValue !== undefined && quotes?.tugo?.price?.tripCancelationInterruptionValue > 0) {
      setTripCancellationInterruptionValue(quotes?.tugo?.price?.tripCancelationInterruptionValue);
    }
  }, [quotes?.tugo?.price?.tripCancelationInterruptionValue]);

  for (const traveller of travellers) {
    if (getAge(traveller.birth_date) < 21) {
      coverageOptions = Object.keys(sportsCoverageOptions).filter(
        sport => !sportsCoverageOptions[sport]["21above"]
      )
      break
    }
  }

  const onSelectSport = sport => {
    if (sport === "None") {
      const preExisting = optionsFromState.find(
        opt => opt.code === "include-pre-existing-coverage"
      )
      dispatch({
        type: ACTIONS.DELETE_ALL_SPORT_OPTIONS,
        payload: preExisting,
      })
      setNeedCover("No")
      onChangeOpt()
      return
    }
    if (
      !optionsFromState.find(
        opt => opt.code === sportsCoverageOptions[sport].code
      )
    ) {
      dispatch({
        type: ACTIONS.SET_OPTIONS,
        payload: { code: sportsCoverageOptions[sport].code },
      })
      onChangeOpt()
      return
    }
    if (
      optionsFromState.find(
        opt => opt.code === sportsCoverageOptions[sport].code
      )
    ) {
      dispatch({
        type: ACTIONS.DELETE_OPTION,
        payload: { code: sportsCoverageOptions[sport].code },
      })
      onChangeOpt()
    }
  }

  const selected = opt => {
    if (optionsFromState.find(o => o.code === sportsCoverageOptions[opt].code))
      return true
    return false
  }

  const onToggleOption = (option) => {
    switch (option) {
      case 'needCover':
        const newNeedCover = needCover === 'Yes' ? 'No' : 'Yes';
        onSelectSport('None');
        setNeedCover(newNeedCover);
        break;
      case 'tripInterruptionCancellation':
        const newNeedTripInterruptionCancellation = needTripInterruptionCancellation === 'Yes' ? 'No' : 'Yes';
        dispatch({
          type: newNeedTripInterruptionCancellation === 'Yes' ? ACTIONS.SET_OPTIONS : ACTIONS.DELETE_OPTION,
          payload: { code: 'include-trip-cancellation-interruption', value: 1000 },
        });
        setNeedTripInterruptionCancellation(newNeedTripInterruptionCancellation);
        break;
      case 'tripInterruption':
        const newNeedTripInterruption = needTripInterruption === 'Yes' ? 'No' : 'Yes';
        dispatch({
          type: newNeedTripInterruption === 'Yes' ? ACTIONS.SET_OPTIONS : ACTIONS.DELETE_OPTION,
          payload: { code: 'include-trip-interruption', value: 1500 },
        });
        setNeedTripInterruption(newNeedTripInterruption);
        break;
    }
    onChangeOpt();
  }

  return (
    <div>
      <div className="flex flex-row">
        <div className="basis-1/2 font-semibold">
          {intl.formatMessage({ id: "extra" })} {intl.formatMessage({ id: "extraSub" })}
        </div>
        <div className="basis-1/2">
          <div className="flex flex-row">
            <div className="basis-1/4">
              <input
                type="radio"
                checked={needCover === 'Yes'}
                name="extraSportsCoverage"
                className="form-radio"
                onChange={() => onToggleOption('needCover')}
              /> {intl.formatMessage({ id: "Yes" })}
            </div>
            <div className="basis-1/4">
              <input
                type="radio"
                checked={needCover === 'No'}
                name="extraSportsCoverage"
                className="form-radio"
                onChange={() => onToggleOption('needCover')}
              /> {intl.formatMessage({ id: "No" })}
            </div>
          </div>
          {needCover === "Yes" && <div className="mt-3 flex flex-wrap">
            {coverageOptions.map(opt => (
              <div key={opt}>
                <OptionContainer active={selected(opt)}>
                  <p onClick={e => onSelectSport(opt)}>
                    {intl.formatMessage({ id: opt })}
                  </p>
                </OptionContainer>
              </div>
            ))}
          </div>}
        </div>
      </div>

      <div className="flex flex-row mt-6">
        <div className="basis-1/2 font-semibold">
          {intl.formatMessage({ id: "extraTripInterruptionCancellation" })} {intl.formatMessage({ id: "extraSub" })}
        </div>
        <div className="basis-1/2">
          <div className="flex flex-row">
            <div className="basis-1/4">
              <input
                disabled={needTripInterruption === 'Yes'}
                type="radio"
                checked={needTripInterruptionCancellation === 'Yes'}
                name="extraTripInterruptionCancellation"
                className="form-radio"
                onChange={() => onToggleOption('tripInterruptionCancellation')}
              /> {intl.formatMessage({ id: "Yes" })}
            </div>
            <div className="basis-1/4">
              <input
                disabled={needTripInterruption === 'Yes'}
                type="radio"
                checked={needTripInterruptionCancellation === 'No'}
                name="extraTripInterruptionCancellation"
                className="form-radio"
                onChange={() => onToggleOption('tripInterruptionCancellation')}
              /> {intl.formatMessage({ id: "No" })}
            </div>
          </div>
          {needTripInterruptionCancellation === "Yes" && <div className="mt-3">
            <div>
              <span>{intl.formatMessage({ id: "tugo.trip-cancellation-interruption-label" })}</span>&nbsp;
              <div style={{ display: 'inline-block', position: 'relative' }}>
                <IoMdInformationCircle
                  css={css`
                    margin-bottom: 0.25rem;
                    cursor: pointer;
                  `}
                  onMouseOver={() => {
                    setShowInfo({
                      tripInterruption: undefined,
                      tripCancellationInterruption: "btn.apply"
                    });
                  }}
                  onMouseOut={() => {
                    setShowInfo({
                      tripInterruption: undefined,
                      tripCancellationInterruption: undefined
                    });
                  }}
                />
                {showInfo.tripCancellationInterruption && (
                  <InfoDialogue content={"tugo.trip-cancellation-interruption-description"} />
                )}
              </div>
            </div>
            <div>
              <input
                type="number"
                className="form-input"
                value={tripCancellationInterruptionValue}
                onChange={(e) => setTripCancellationInterruptionValue(e.target.value)}
                style={{ width: '100px' }}
              />
              <input
                type="button"
                className="form-button ml-2"
                value={intl.formatMessage({ id: "btn.updateQuote" })}
                onClick={() => {
                  setTripCancellationInterruptionValue(tripCancellationInterruptionValue || 400);
                  dispatch({
                    type: ACTIONS.SET_OPTIONS,
                    payload: { code: 'include-trip-cancellation-interruption', value: tripCancellationInterruptionValue || 400 },
                  });
                  onChangeOpt();
                }}
              />
            </div>
          </div>}
        </div>
      </div>

      <div className="flex flex-row mt-6 mb-6">
        <div className="basis-1/2 font-semibold">
          {intl.formatMessage({ id: "extraTripInterruption" })} {intl.formatMessage({ id: "extraSub" })}
        </div>
        <div className="basis-1/2">
          <div className="flex flex-row">
            <div className="basis-1/4">
              <input
                disabled={needTripInterruptionCancellation === 'Yes'}
                type="radio"
                checked={needTripInterruption === 'Yes'}
                name="needTripInterruption"
                className="form-radio"
                onChange={() => onToggleOption('tripInterruption')}
              /> {intl.formatMessage({ id: "Yes" })}
            </div>
            <div className="basis-1/4">
              <input
                disabled={needTripInterruptionCancellation === 'Yes'}
                type="radio"
                checked={needTripInterruption === 'No'}
                name="needTripInterruption"
                className="form-radio"
                onChange={() => onToggleOption('tripInterruption')}
              /> {intl.formatMessage({ id: "No" })}
            </div>
          </div>
          {needTripInterruption === "Yes" && <div className="mt-3">
            <div>
              <span>{intl.formatMessage({ id: "tugo.trip-interruption-label" })}</span>&nbsp;
              <div style={{ display: 'inline-block', position: 'relative' }}>
                <IoMdInformationCircle
                  css={css`
                    margin-bottom: 0.25rem;
                    cursor: pointer;
                  `}
                  onMouseOver={() => {
                    setShowInfo({
                      tripInterruption: "btn.apply",
                      tripCancellationInterruption: "tugo.trip-interruption-description"
                    });
                  }}
                  onMouseOut={() => {
                    setShowInfo({
                      tripInterruption: undefined,
                      tripCancellationInterruption: undefined
                    });
                  }}
                />
                {showInfo.tripCancellationInterruption && (
                  <InfoDialogue content={"tugo.trip-interruption-description"} />
                )}
              </div>
            </div>
            <div className="flex flex-wrap">
              {tripInterruptionOptions.map(opt => (
                <div key={opt}>
                  <OptionContainer active={tripInterruptionValue === tugoTripInterruption[opt].value}>
                    <p onClick={() => {
                      setTripInterruptionValue(tugoTripInterruption[opt].value);
                      dispatch({
                        type: ACTIONS.SET_OPTIONS,
                        payload: { code: 'include-trip-interruption', value: tugoTripInterruption[opt].value },
                      });
                      onChangeOpt();
                    }}>{opt}</p>
                  </OptionContainer>
                </div>
              ))}
            </div>
          </div>}
        </div>
      </div>
    </div>
  )
}

const OptionContainer = styled.div`
  ${props =>
    !props.active &&
    !props.grayout &&
    ":hover { p {color: var(--clr-primary-100);border: 1px solid var(--clr-primary-100); }}"}

  p {
    border: 1px solid
      ${props =>
    props.grayout ? "var(--clr-neutral-300)" : "var(--clr-primary-300)"};
    border-radius: var(--border-radius);
    margin: 0.25rem;
    padding: 0.25rem;
    color: ${props =>
    props.active
      ? "var(--clr-neutral-100)"
      : props.grayout
        ? "var(--clr-neutral-300)"
        : "var(--clr-primary-300)"};
    box-shadow: ${props => (props.active ? "none" : "var(--dark-shadow-sb)")};
    cursor: pointer;
    pointer-events: auto;
    background: ${props =>
    props.active ? "var(--clr-primary-300)" : "var(--clr-neutral-100)"};
    user-select: none;
    transition: var(--transition);
  }
`

export default TugoExtraOptions

import ACTIONS from "../actions/actionTypes"

export const options = (
  state = [{ code: "include-pre-existing-coverage" }],
  action
) => {
  const { type, payload } = action
  if (type === ACTIONS.SET_OPTIONS) {
    if (!state.find(o => o.code === payload.code)) {
      return [...state, payload]
    } else {
      const indexOf = state.findIndex(o => o.code === payload.code);
      const newState = [...state];
      newState[indexOf] = payload;
      return newState;
    }
  }
  if (type === ACTIONS.DELETE_ALL_SPORT_OPTIONS) {
    return [payload]
  }
  if (type === ACTIONS.DELETE_OPTION) {
    return state.filter(o => o.code !== payload.code)
  }
  return state
}

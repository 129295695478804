import React from "react"
import { Link, navigate } from "gatsby"
import { css } from "@emotion/core"
import { FaBars } from "react-icons/fa"
import { useIntl } from "react-intl"

import NavLinks from "./NavLinks"
import Logo from "./Logo"
import { SecondaryButton } from "./buttons"
import { Stepper, LanguageSwitcher } from "."
import { mq } from "../utils/media-query"

const NavBar = ({ bkgColor, open, quoting }) => {
  const intl = useIntl()
  return (
    <nav
      css={css`
        display: flex;
        height: 7rem;
        background-color: ${bkgColor};
        align-items: center;
      `}
    >
      <div
        css={css`
          width: 90vw;
          max-width: var(--max-width);
          margin: 0 auto;
          display: grid;
          grid-template-columns: auto 1fr auto;
          column-gap: 2rem;
          position: relative;
          align-items: center;
        `}
      >
        <Link
          to="/"
          css={css`
            width: 120px;
            display: block;
            align-self: flex-start;

            & img {
              height: 100%;
            }
          `}
        >
          <Logo />
        </Link>
        <div
          css={css`
            @media (min-width: 1010px) {
              display: none;
            }
          `}
        ></div>
        {!quoting && (
          <button
            css={css`
              font-size: 2rem;
              background: transparent;
              border-color: transparent;
              color: var(--clr-primary-100);
              cursor: pointer;
              transition: var(--transition);

              &:hover {
                color: #625e9c;
              }

              &:focus,
              &:active {
                outline: none;
              }

              @media (min-width: 1010px) {
                display: none;
              }
            `}
            onClick={open}
          >
            <FaBars />
          </button>
        )}
        {!quoting && <NavLinks />}
        {!quoting && (
          <div
            css={css`
              display: flex;
            `}
          >
            <SecondaryButton
              buttonColor="#446663"
              textSize="0.75rem"
              textWeight="700"
              css={css`
                display: none;
                padding: 0.375rem 1rem;

                @media (min-width: 1010px) {
                  display: inline-block;
                  margin-right: 1rem;
                }
              `}
              onClick={() => {
                navigate("/get-quote-details")
              }}
            >
              {intl.formatMessage({ id: "nav.btn" })}
            </SecondaryButton>
            <div
              css={css`
                display: none;

                @media (min-width: 1010px) {
                  display: flex;
                }
              `}
            >
              <LanguageSwitcher />
            </div>
          </div>
        )}
        {quoting && <Stepper />}
        {quoting && (
          <div
            css={css`
              display: flex;

              @media (min-width: 1010px) {
                align-self: center;
              }
            `}
          >
            <LanguageSwitcher />
          </div>
        )}
      </div>
    </nav>
  )
}

export default NavBar

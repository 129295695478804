import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IntlProvider } from "react-intl"
import { getHealth, setToken } from "../redux/actions"
import text from "../utils/text-ch-en"

const AppWrapper = ({ children }) => {
  const dispatch = useDispatch()
  const apiHealth = useSelector(state => state.apiHealth)
  const locale = useSelector(state => state.locale)
  const sessionStorage = typeof window !== "undefined" && window.sessionStorage
  const location = typeof window !== "undefined" && window.location
  const localStorage = typeof window !== "undefined" && window.localStorage
  const pathname = useSelector(state => state.currentPath)

  useEffect(() => {
    dispatch(getHealth())
    let params = new URL(location).searchParams
    let name = params.get("name")
    // save affiliate name into localStorage
    if (name) {
      localStorage.setItem("affiliateName", name)
    }
  }, [dispatch])
  // useEffect(() => {
  //   dispatch(setToken())
  // }, [apiHealth, dispatch])

  useEffect(() => {
    if (!pathname.includes("payment")) sessionStorage.clear()
  }, [pathname])
  return (
    <IntlProvider locale={locale} messages={text[locale]}>
      {children}
    </IntlProvider>
  )
}

export default AppWrapper

import { provinceCode } from "./provinces"
import { paymentInfoItems, paymentItems } from "./payment-info"
import { getAge } from "../utils/time-related-func"
import moment from "moment"

export const coverageDeductibleCombo = conditions => {
  return Object.keys(conditions).reduce((acc, key) => {
    if (key !== "travelance") {
      acc[key] = {}
      acc[key]["deductible"] = conditions[key]["deductible"]["options"]
      acc[key]["coverage"] = conditions[key]["limit"]["options"]
    }
    return acc
  }, {})
}

export const getUniqueVals = (insurer, type, all) => {
  let result = all[insurer][type]
  for (let key of Object.keys(all)) {
    if (key === insurer) continue
    result = result.filter(amount => !all[key][type].includes(amount))
  }
  return result
}

export const getUnavailableCoveragesOrDeductibles = (
  unAvailType,
  selected,
  all,
  above80,
  preExisting,
  extraSportCoverage
) => {
  let result = []

  // only Tugo available for travellers above 80 yrs old or need extra sport coverage
  if (extraSportCoverage) {
    if (unAvailType === "coverage") result.push(200000)
  }

  // tugo only
  if (above80 && unAvailType === 'deductible') {
    result = result.concat([100, 250]);
  }

  // only destination provides exclusion of pre-existing condition
  // Destination only
  if (!preExisting) {
    if (unAvailType === "coverage") result.push(150000)
    result = result.concat([100, 250])
  }

  if (unAvailType === "deductible") {
    result = result.concat(
      Object.keys(all).reduce((acc, key) => {
        if (!all[key]["coverage"].includes(selected)) {
          const uniqueVals = getUniqueVals(key, "deductible", all)
          acc = [...acc, ...uniqueVals]
        }
        return acc
      }, [])
    )
  }

  if (unAvailType === "coverage") {
    result = result.concat(
      Object.keys(all).reduce((acc, key) => {
        if (!all[key]["deductible"].includes(selected)) {
          const uniqueVals = getUniqueVals(key, "coverage", all)
          acc = [...acc, ...uniqueVals]
        }
        return acc
      }, [])
    )

    if (selected === 100) {
      result = result.concat([10000, 300000])
    }
    if (selected === 150) {
      result = result.concat([150000])
    }
  }

  // remove 2million tugo student
  result.splice(result.indexOf(2000000), 1);

  return result
}

export const checkInsuerAvailability = (
  coverage,
  deductible,
  all,
  preExisting,
  travellers,
  extraSportCover,
  province,
  tripCancellationInterruption,
  tripInterruption
) => {
  let result = Object.keys(all).reduce((acc, key) => {
    acc[key] = {}
    acc[key]["quoteNotAvailMessage"] = null
    acc[key]["avail"] = all[key]["coverage"].includes(coverage) && all[key]["deductible"].includes(deductible);

    const ages = travellers.map((t) => getAge(t.birth_date));

    if (key === 'tugoStudent' && Math.max(ages) <= 69) {
      acc[key]["avail"] = true;
    }

    if (!acc[key]["avail"]) {
      acc[key]["quoteNotAvailMessage"] = `${key}.NoCoverageOrDeductible`
    }
    return acc
  }, {})


//   //temporarily disable destination
  // result.destination.avail = false

  // gms cannot have "other" as an option
  if (travellers.find(traveler => traveler.relationship_status === "other")) {
    result.gms.avail = false
    result.gms.quoteNotAvailMessage = "gms.nonFamilyMember"
  }

  if (!preExisting) {
    result.gms.avail = false
    result.gms.quoteNotAvailMessage = "gms.withoutPreExistingCondition"
    result.tugo.avail = false
    result.tugo.quoteNotAvailMessage = "tugo.withoutPreExistingCondition"
    result.tugoStudent.avail = false
    result.tugoStudent.quoteNotAvailMessage = "tugo.withoutPreExistingCondition"
  }

  if (travellers.length > 6) {
    result.gms.avail = false
    result.gms.quoteNotAvailMessage = "gms.travelersNum"
  }

  if (extraSportCover) {
    result.gms.avail = false
    result.gms.quoteNotAvailMessage = "gms.sportsCoverage"
    result.destination.avail = false
    result.destination.quoteNotAvailMessage = "destination.sportsCoverage"
    result.destination.avail = false
    result.destination.quoteNotAvailMessage = "destination.sportsCoverage"
    result.tugoStudent.avail = false
    result.tugoStudent.quoteNotAvailMessage = "tugoStudent.sportsCoverage"
  }

  if (tripCancellationInterruption || tripInterruption) {
    result.gms.avail = false
    result.gms.quoteNotAvailMessage = "gms.travelCancellationCoverage"
    result.destination.avail = false
    result.destination.quoteNotAvailMessage = "destination.travelCancellationCoverage"
    result.tugoStudent.avail = false
    result.tugoStudent.quoteNotAvailMessage = "tugoStudent.travelCancellationCoverage"
  }

  if (province === "QC") {
    result.gms.avail = false
    result.gms.quoteNotAvailMessage = "gms.notForQC"
  }

  for (const traveler of travellers) {
    // GMS doesn't allow dependant to be > 19 yrs old
    if (
      getAge(traveler.birth_date) > 19 &&
      traveler.relationship_status === "dependant"
    ) {
      return {
        gms: {
          avail: false,
          quoteNotAvailMessage: "gms.dependantAge",
        },
        tugo: {
          avail: result.tugo.avail,
          quoteNotAvailMessage: result.tugo.quoteNotAvailMessage,
        },
        tugoStudent: {
          avail: result.tugoStudent.avail,
          quoteNotAvailMessage: result.tugoStudent.quoteNotAvailMessage,
        },
        destination: {
          avail: result.destination.avail,
          quoteNotAvailMessage: result.destination.quoteNotAvailMessage,
        },
      }
    }
    
    if (getAge(traveler.birth_date) >= 80) {
      // only Tugo is available for travellers who are >= 80 yrs old
      return {
        gms: {
          avail: false,
          quoteNotAvailMessage: "gms.olderThan80",
        },
        tugo: {
          avail: result.tugo.avail,
          quoteNotAvailMessage: result.tugo.quoteNotAvailMessage,
        },
        tugoStudent: {
          avail: result.tugoStudent.avail,
          quoteNotAvailMessage: result.tugoStudent.quoteNotAvailMessage,
        },
        destination: {
          avail: false,
          quoteNotAvailMessage: "destination.olderThan80",
        },
      }
    }
  }

  return result
}

export const getCoverages = all => {
  return [
    ...new Set(
      Object.keys(all)
        .reduce((acc, key) => {
          acc = acc.concat(all[key]["coverage"])
          return acc
        }, [])
        .sort((a, b) => a - b)
    ),
  ]
}
export const getDeductibles = all => {
  return [
    ...new Set(
      Object.keys(all)
        .reduce((acc, key) => {
          acc = acc.concat(all[key]["deductible"])
          return acc
        }, [])
        .sort((a, b) => a - b)
    ),
  ]
}

export const validTravelPlan = (travelPlan, choosePlaceholder = null) => {
  if (
    !travelPlan.travelFrom ||
    (choosePlaceholder && travelPlan.travelFrom === choosePlaceholder)
  )
    return false
  if (
    !travelPlan.travelTo ||
    (choosePlaceholder && travelPlan.travelTo === choosePlaceholder)
  )
    return false
  if (!travelPlan.startDate) return false
  if (!travelPlan.endDate) return false
  if (!travelPlan.arrivalDate) return false
  return true
}

export const validTravellers = travellers => {
  for (const traveller of travellers) {
    if (
      !traveller.first_name ||
      !traveller.last_name ||
      !traveller.birth_date ||
      !moment(traveller.birth_date, "YYYY-MM-DD").isValid() ||
      !traveller.birth_date.toString().match(/^\d{4}-\d{2}-\d{2}$/) ||
      !traveller.gender ||
      !traveller.relationship_status ||
      !traveller.mq ||
      (traveller.gender !== "male" && traveller.gender !== "female")
    )
      return false
  }

  return true
}

export const setTravelPlanPayload = (
  travelPlan,
  purpose = "not specified"
) => ({
  departing_date: travelPlan.startDate.format("YYYY-MM-DD"),
  returning_date: travelPlan.endDate.format("YYYY-MM-DD"),
  arriving_date: travelPlan.arrivalDate.format("YYYY-MM-DD"),
  to: provinceCode[travelPlan.travelTo],
  from: travelPlan.travelFrom,
  purpose,
})

export const setPayloadForQuote = (
  travelPlanPayload,
  travellersPayload,
  coverage,
  deductible,
  options,
  famRate
) => {
  return {
    travel_plan: travelPlanPayload,
    travelers: travellersPayload,
    policy: {
      coverage,
      deductible,
    },
    options,
    famRate,
  }
}

// const mockPlan = {
//   insurer: "gms",
//   logo: "gms.fluid",
//   premium: 123.0,
//   coverageItems: ["Medical emergency", "Pre-existing conditions"],
//   triplength
// }

export const setPlanCardData = (
  insurer,
  logo,
  premium,
  coverageItems,
  triplength,
  notAvailMessage
) => ({
  insurer,
  logo,
  premium,
  coverageItems,
  triplength,
  notAvailMessage,
})

export const isFamRate = travellers => {
  let result = { destination: false, tugo: false, tugoStudent: false }

  if (travellers.length < 3) return result

  result.destination = true
  result.tugo = true
  result.tugoStudent = true;

  if (travellers.length > 6) {
    result.tugo = false
    result.tugoStudent = false
  }

  for (const traveller of travellers) {
    if (
      traveller.relationship_status === "policyholder" ||
      traveller.relationship_status === "spouse"
    ) {
      if (getAge(traveller.birth_date) > 59) {
        result.tugo = false;
        result.tugoStudent = false;
      }
      if (getAge(traveller.birth_date) > 69) {
        result.destination = false
      }
    }

    if (
      traveller.relationship_status === "dependant" &&
      getAge(traveller.birth_date) > 21
    ) {
      result.destination = false;
      result.tugo = false;
      result.tugoStudent = false;
    }
  }
  return result
}

export const travellerOver80 = travellers => {
  for (const traveller of travellers) {
    if (getAge(traveller.birth_date) >= 80) return true
  }
  return false
}

export const checkExtraSportCoverage = options => {
  if (
    options.length > 0 &&
    options.find(opt => opt.code !== "include-pre-existing-coverage")
  )
    return true

  return false
}

export const checkTripCancellationInterruption = options => {
  if (
    options.length > 0 &&
    options.find(opt => opt.code === "include-trip-cancellation-interruption")
  )
    return true

  return false
}

export const checkTripInterruption = options => {
  if (
    options.length > 0 &&
    options.find(opt => opt.code === "include-trip-interruption")
  )
    return true

  return false
}

export const ifAllQuotesLoaded = (quotes, availability) => {
  for (const insurer of Object.keys(quotes)) {
    if (
      availability[insurer].avail &&
      (Object.keys(quotes[insurer]).length <= 0 ||
        !quotes[insurer].hasOwnProperty('price') ||
        !quotes[insurer].price.hasOwnProperty('premium'))
    )
      return false
  }
  return true
}

export const isCompareInsurers = compareInsurer => {
  for (const insurer of Object.keys(compareInsurer)) {
    if (compareInsurer[insurer]) return true
  }
  return false
}

export const getPaymentInfo = () => {
  return Object.keys(paymentInfoItems).reduce((acc, i) => {
    acc[i] = typeof window !== "undefined" && window.sessionStorage.getItem(i)
    return acc
  }, {})
}
export const getPayment = () => {
  return paymentItems.reduce((acc, i) => {
    acc[i] = typeof window !== "undefined" && window.sessionStorage.getItem(i)
    return acc
  }, {})
}

export const validPaymentInfo = () => {
  const paymentInfo = getPaymentInfo()
  if (
    !paymentInfo.email ||
    !paymentInfo.email.match(/\S+@\S+\.\S+/) === null ||
    !paymentInfo.confirmEmail ||
    paymentInfo.email !== paymentInfo.confirmEmail ||
    !paymentInfo.address ||
    !paymentInfo.city ||
    !paymentInfo.province ||
    !paymentInfo.postalCode ||
    !paymentInfo.phone
  )
    return false
  return true
}

export const validPayment = () => {
  const payment = getPayment()
  if (
    !payment.cardHolderName ||
    !payment.cardNumber ||
    !payment.expireMonth ||
    !payment.expireYear ||
    !payment.cvc
  )
    return false
  return true
}

const gmsVisitorType = {
  notInCA: "OutsideOfCanada",
  inCA: {
    replace: "InCanadaReplacingACurrentPlan",
    lessThan30days: "InCanadaForLessThan30DaysAndNoCanadianPlan",
    moreThan30days: "InCanadaForMoreThan30DaysAndNoCanadianPlan",
  },
}

export const setPurchasePayload = (
  address,
  city,
  province,
  postalCode,
  email,
  phone,
  travelPlan,
  travellers,
  coverage,
  deductible,
  purchaseConfirmation,
  nameOnCard,
  cardNumber,
  expireMonth,
  expireYear,
  cvc,
  famRate,
  options,
  insurer,
  quotes,
  studentNumber
) => {
  return {
    language: "en",
    address: {
      street_line_1: address,
      street_line_2: "",
      city,
      province: province.toUpperCase(),
      postal_code: postalCode,
    },
    email,
    phone_number: phone,
    student_number: studentNumber,
    application: {
      travel_plan: setTravelPlanPayload(travelPlan),
      travelers: travellers,
      policy: {
        coverage,
        deductible,
      },
      visitor_details: {
        visitor_type:
          purchaseConfirmation.location &&
          !purchaseConfirmation.location.includes("not")
            ? purchaseConfirmation.situation.includes("replace")
              ? gmsVisitorType["inCA"]["replace"]
              : purchaseConfirmation.situation.includes("less")
              ? gmsVisitorType["inCA"]["lessThan30days"]
              : gmsVisitorType["inCA"]["moreThan30days"]
            : gmsVisitorType["notInCA"],
        existing_plan: {
          policy_number: purchaseConfirmation.currentPolicy.policyNumber,
          company_name: purchaseConfirmation.currentPolicy.company,
          expiry_date:
            purchaseConfirmation.currentPolicy.endDate &&
            purchaseConfirmation.currentPolicy.endDate.format("YYYY-MM-DD"),
        },
      },
      options,
      famRate,
    },
    payment: {
      name_on_card: nameOnCard.trim(),
      number: cardNumber.trim(),
      expiry_month: expireMonth.trim(),
      expiry_year: "20" + expireYear.trim(),
      cvd: cvc.trim(),
    },
    quote: Object.assign({}, quotes[insurer], {
      ...quotes[insurer],
      ...{
        price: {
          premium: quotes[insurer].price.premium.toString(),
          tax: quotes[insurer].price.tax.toString(),
        },
      },
    }),
    affiliateName:
      typeof window !== "undefined" &&
      window.localStorage.getItem("affiliateName"),
  }
}

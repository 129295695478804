import React, { useState } from "react"
import { css } from "@emotion/core"
import { useIntl } from "react-intl"

const Card = ({
  image,
  svg,
  caption,
  text,
  imgStyle,
  captionStyle,
  textStyle,
  cardStyle,
  hoverEffect,
  onClick,
  hoverText,
  capId,
  textId,
  hoverTextId,
}) => {
  const intl = useIntl()
  const [showText, setShowText] = useState(false)
  const onHover = hoverText ? () => setShowText(true) : () => {}
  const onMouseLeave = hoverText ? () => setShowText(false) : () => {}

  return (
    <article
      css={css`
        ${cardStyle}
        ${hoverEffect};
      `}
      onClick={onClick}
      onMouseOver={onHover}
      onMouseOut={onMouseLeave}
    >
      {showText ? (
        <div
          css={css`
            padding: 1rem;
            letter-spacing: var(--letter-spacing-md);
            text-align: left;
            display: grid;
            grid-template-rows: 15% 85%;
            align-items: center;
            height: 100%;
            width: 100%;

            p {
              font-weight: 700;
              text-transform: capitalize;
              margin: 0;
              align-self: top;
            }

            @media (min-width: 600px) {
              padding: 0.5rem;
            }
          `}
        >
          <p>{capId ? intl.formatMessage({ id: capId }) : caption}</p>
          <div>
            {hoverTextId ? intl.formatMessage({ id: hoverTextId }) : hoverText}
          </div>
        </div>
      ) : (
        <div css={imgStyle}>
          {svg && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 80 80"
              css={css`
                max-width: 100%;
                margin: 0 auto;
              `}
            >
              {svg}
            </svg>
          )}
          {image && (
            <img
              src={image.src}
              alt={image.alt}
              css={css`
                max-width: 100%;
              `}
            />
          )}
        </div>
      )}
      {caption && !showText && (
        <p css={captionStyle}>
          {capId ? intl.formatMessage({ id: capId }) : caption}
        </p>
      )}
      {!showText && (
        <p css={textStyle}>
          {textId ? intl.formatMessage({ id: textId }) : text}
        </p>
      )}
    </article>
  )
}

export default Card
